import clsx from 'clsx'
import { FC } from 'react'
import { BasicStyledComponent, DSTagEntity, DSTeamMember } from 'shared-definitions/types'
import styles from './PostFollowLineUI.module.css'
import dynamic from 'next/dynamic'

const ViafouraFollow = dynamic(() => import('../service/ViafouraFollow'), {
  ssr: false,
})

// author, categories, topics
interface PostFollowLineUIProps {
  authors: DSTeamMember[]
  topics: readonly DSTagEntity[]
  categories: readonly DSTagEntity[]
}

const PostFollowLineUI: FC<PostFollowLineUIProps & BasicStyledComponent> = ({
  className,
  authors,
  topics,
  categories,
}) => (
  <div className={clsx(styles.container, className)}>
    <div className={styles.title}>{'Follow'}</div>
    <div className={styles.buttons}>
      {authors.map(author => (
        <ViafouraFollow type="author" key={author.slug} id={author.slug} label={author.name} />
      ))}
      {topics.map(topic => (
        <ViafouraFollow type="topic" key={topic.slug} id={topic.slug} label={topic.label} />
      ))}
      {categories.map(category => (
        <ViafouraFollow
          type="category"
          key={category.slug}
          id={category.slug}
          label={category.label}
        />
      ))}
    </div>
  </div>
)

export default PostFollowLineUI
