import SplitterLine from 'shared-components/ui/SplitterLine'
import PostShortLine from 'shared-components/ui/PostShortLine'
import NC from 'nc/NC'
import ContentLayout from 'shared-components/ui/ContentLayout'
import EventHero from 'shared-components/ui/EventHero'
import stylesCommon from './EventTemplate.TextTemplate.module.css'
import TopBanner from 'shared-components/ad/TopBanner'
import styleUtils from 'shared-components/styles/util.module.css'
import styles from './EventTemplate.module.css'
import { useTaglessAd } from 'shared-components/hooks/use-tagless-ad'
import { DSEventPage } from 'shared-definitions/types'
import { DSNCAA } from 'types.aa.ds'
import Disclaimer from 'shared-components/ui/Disclaimer'
import { useArticleViewed } from 'shared-components/hooks/use-article-viewed'
import PostFollowLineUI from 'shared-components/ui/PostFollowLineUI'

interface EventTemplateProps {
  page: DSEventPage<DSNCAA>
}

const EventTemplate: React.VFC<EventTemplateProps> = ({ page }) => {
  useTaglessAd()

  return (
    <>
      <Disclaimer />
      <TopBanner />
      <div
        className={styleUtils.wrapper}
        ref={useArticleViewed({
          title: page.title,
          url: page.pagePath,
          categoryLevel: 1,
          categoryTitle: page.meta.categories[0]?.label ?? '',
          author: page.authors.authoredBy.map(author => author.name).join(', '),
          wordCount: parseInt(page.meta.dataLayer?.word_count?.toString() ?? '0', 10),
          date: page.meta.dataLayer?.original_pub_date?.toString() ?? '',
        })}
      >
        <ContentLayout className={stylesCommon.layout}>
          <EventHero post={page} />
          <SplitterLine className={styles.splitter} />
          <PostShortLine post={page} />
          {page.blocks.map((item, index) => (
            <NC key={index} data={item} tpl="text" index={index} />
          ))}
          <PostFollowLineUI
            className={stylesCommon.followLine}
            authors={page.authors.authoredBy}
            topics={page.meta.topics}
            categories={page.meta.categories}
          />
        </ContentLayout>
      </div>
    </>
  )
}

export default EventTemplate
