/* eslint-disable react/self-closing-comp */
import styles from './Comments.module.css'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'
import { PostContext } from 'shared-components/contexts/PostContext'
import dynamic from 'next/dynamic'

const ViafouraConversations = dynamic(() => import('../service/ViafouraConversations'), {
  ssr: false,
})

interface CommentsProps extends BasicStyledComponent {}

// Docs: https://documentation.viafoura.com/docs/css-customizations
const Comments: React.VFC<CommentsProps> = ({ className }) => {
  const { engagementContainerId: id } = PostContext.useContainer()
  const { sponsored } = PostContext.useContainer()

  if (sponsored || !id) {
    return null
  }

  return (
    <div
      data-container-type="content"
      className={clsx(styles.container, className)}
      id="comments-section"
    >
      <ViafouraConversations containerId={id} />
    </div>
  )
}

export default Comments
