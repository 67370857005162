import SidebarFloatingAd from 'components/ad/SidebarFloatingAd'
import NC from 'nc/NC'
import { useArticleViewed } from 'shared-components/hooks/use-article-viewed'
import React from 'react'
import TopBanner from 'shared-components/ad/TopBanner'
import { useAffilate } from 'shared-components/hooks/use-affilate'
import { useTaglessAd } from 'shared-components/hooks/use-tagless-ad'
import PostNavigation from 'shared-components/layout/PostNavigation'
import ScrollEventsListener from 'shared-components/service/ScrollEventsListener'
import styleUtils from 'shared-components/styles/util.module.css'
import AdaptiveThreeColumnLayout from 'shared-components/ui/AdaptiveThreeColumnLayout'
import BreadCrumbs from 'shared-components/ui/BreadCrumbs'
import PostFollowLineUI from 'shared-components/ui/PostFollowLineUI'
import Comments from 'shared-components/ui/Comments'
import Disclaimer from 'shared-components/ui/Disclaimer'
import PostShortLine from 'shared-components/ui/PostShortLine'
import PostTags from 'shared-components/ui/PostTags'
import PostTitle from 'shared-components/ui/PostTitle'
import SidedCategoryMenu from 'shared-components/ui/SidedCategoryMenu'
import SplitterLine from 'shared-components/ui/SplitterLine'
import { DSPostPageAA } from 'types.aa.ds'
import stylesCommon from './PostTemplate.ReviewTemplate.module.css'
import styles from './PostTemplate.module.css'

interface PostTemplateProps {
  page: DSPostPageAA
}

const PostTemplate: React.VFC<PostTemplateProps> = ({ page }) => {
  useTaglessAd()
  useAffilate(page.productBars)
  const [first, ...blocks] = page.blocks

  return (
    <>
      <ScrollEventsListener />
      <Disclaimer />
      <div
        className={styleUtils.wrapper}
        ref={useArticleViewed({
          title: page.title,
          url: page.pagePath,
          categoryLevel: 1,
          categoryTitle: page.meta.categories[0]?.label ?? '',
          author: page.authors.authoredBy.map(author => author.name).join(', '),
          wordCount: parseInt(page.meta.dataLayer?.word_count?.toString() ?? '0', 10),
          date: page.meta.dataLayer?.original_pub_date?.toString() ?? '',
        })}
      >
        <BreadCrumbs list={page.breadcrumbs} className={stylesCommon.breadCrumbs} />
        <PostTitle title={page.title} subtitle={page.subtitle} />
        <PostShortLine post={page} className={styles.shortLine} />
        <TopBanner />
        <SplitterLine className={styles.splitter} />
        <AdaptiveThreeColumnLayout
          left={
            page.sideMenu.length ? (
              <SidedCategoryMenu
                className={stylesCommon.sidebarPostNav}
                title="Go deeper"
                menu={page.sideMenu}
              />
            ) : null
          }
          className={styles.content}
          content={
            <>
              <NC data={first} tpl="post" index={0} />

              {blocks.map((item, index) => (
                <NC key={index + 1} data={item} tpl="text" index={index} />
              ))}

              <PostTags meta={page.meta} className={stylesCommon.postTags} />

              <PostFollowLineUI
                className={stylesCommon.followLine}
                authors={page.authors.authoredBy}
                topics={page.meta.topics}
                categories={page.meta.categories}
              />

              {page.pagePath && page.meta.engagementContainerId ? (
                <Comments className={stylesCommon.comments} />
              ) : null}
            </>
          }
          sidebar={
            <PostNavigation>
              {[...page.sidebarContent, ...page.sidebarAds].map((item, index) => (
                <NC key={index} data={item} tpl="sidebar" index={index} />
              ))}
              <SidebarFloatingAd />
            </PostNavigation>
          }
        />
        {/* <RecommendAA
          containerClassName={stylesCommon.related}
          itemClassName={styleRelatedCard.relatedCard}
          title="More from Android Authority"
        /> */}
      </div>
    </>
  )
}

export default PostTemplate
