import clsx from 'clsx'
import React from 'react'

import { useWindowScrollState } from 'shared-components/hooks/use-window-scroll-state'

import styles from './SidebarWithStickyAds.module.css'

export interface SidebarWithStickyAdsProps {
  className?: string
  render: (index: number) => React.ReactElement
}

const SidebarWithStickyAds: React.VFC<SidebarWithStickyAdsProps> = ({ className, render }) => {
  const [countAd, setCountAd] = React.useState(1)

  const containerRef = React.useRef<HTMLDivElement>(null)

  useWindowScrollState(() => {
    const { current: container } = containerRef
    if (container && container.parentElement) {
      const parenHeight = container.parentElement.scrollHeight
      const windowHeight = window.innerHeight

      if (parenHeight && windowHeight && countAd <= 1) {
        setCountAd(Math.max(1, Math.floor(parenHeight / windowHeight) - 1))
      }
    }
  })

  const adArray = Array.from(Array(Math.max(0, countAd)).keys())

  return (
    <div ref={containerRef} className={clsx(styles.container, className)}>
      {adArray.map(key => (
        <div key={key} className={styles.floatingBox}>
          <div className={styles.floatingAd}>{render(key)}</div>
        </div>
      ))}
    </div>
  )
}

export default SidebarWithStickyAds
